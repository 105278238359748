import React from 'react'
import { useRecoilState } from 'recoil'
import { addedFileState } from '../recoilState'
import { UploadBox } from './UploadBox'
import { Box, TextField, Typography } from '../../ui'

export const Form = ({
  name,
  phoneNumber,
  instagram,
  seo_name,
  setName,
  setPhoneNumber,
  setInstagram,
  setMessage,
  addedFile,
  setAddedFile,
  uploadStatus,
  setUploadStatus,
}) => {
  return (
    <>
      <Box>
        <Typography id='modal-modal-title' variant='h6' component='h2'>
          Form Submission
        </Typography>
        <Typography id='modal-modal-description' sx={{ mt: 1 }}>
          Great! We are really excited for you, Fill out this short form and
          you’ll be good to go.
        </Typography>
      </Box>
      <Box mt={2}>
        <UploadBox
          addedFile={addedFile}
          setAddedFile={setAddedFile}
          seo_name={seo_name}
          setMessage={setMessage}
          uploadStatus={uploadStatus}
          setUploadStatus={setUploadStatus}
        />
      </Box>
      <Box mt={0}>
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          color='primary'
          label='Name'
          variant='outlined'
          fullWidth
        ></TextField>
      </Box>
      <Box mt={2}>
        <TextField
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          color='primary'
          label='Phone Number'
          type='number'
          variant='outlined'
          fullWidth
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 10)
          }}
        ></TextField>
      </Box>
      <Box mt={2}>
        <TextField
          value={instagram}
          onChange={(e) => setInstagram(e.target.value)}
          color='primary'
          label='Intagram url'
          variant='outlined'
          fullWidth
        ></TextField>
      </Box>
    </>
  )
}
