import { Button } from '../ui'
import styled from 'styled-components'

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  z-index: 12;
  transition: max-height 1s ease;
`
export const HeadingMedium = styled.h2`
  position: relative;
  width: 100%;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  margin: 5px 0;
`
export const Paragraph = styled.h4`
  position: relative;
  width: 100%;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  margin: 0px;
  padding: 5px 0;
  box-sizing: border-box;
`
export const QuestionBox = styled.div`
  position: relative;
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
`
export const QuestionText = styled.h3`
  position: relative;
  width: 100%;
  text-align: left;
  font-weight: 700;
  font-size: 22px;
  margin: 0px;
  padding: 5px 0;
  box-sizing: border-box;
`
export const ContactUsButton = styled(Button)`
  &.contactUsButton {
    position: relative;
    cursor: pointer;
    width: fit-content;
    height: auto;
    border-radius: 6px;
    color: #ffffff;
    font-size: 16px;
    padding: 10px 20px;
    margin: 0 auto;
    background: #ff174e;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    transition: all 0.3s linear;

    &:hover {
      background: #ff174e;
    }
  }
`
