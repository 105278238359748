import React, { useState } from 'react'
import { useRecoilState } from 'recoil'
import { uploadProgressState } from '../recoilState'
import { uploadFile, reportFailure } from '../uploadActions'
import { Box, Button, Typography } from '../../ui'
import { Input, UploadFileContainer } from '../styles'
import GreenTick from '../../icons/greentick.svg'

export const UploadBox = ({
  addedFile,
  setAddedFile,
  seo_name,
  setMessage,
  uploadStatus,
  setUploadStatus,
}) => {
  const [progress, setProgress] = useRecoilState(uploadProgressState)
  const [uploading, setUploading] = useState(false)

  const onAddFile = (event) => {
    let fileList = event.target.files
    let file = fileList[0]
    let extension = file.name.match(/(?:\.)\w+$/g)[0].toLowerCase() // assuming that this file has any extension
    let fileSize = Math.round(file.size / 1024)
    if (extension === '.mp4') {
      if (fileSize < 20000) {
        setAddedFile(file)
      } else {
        event.target.value = ''
        setMessage({ type: 'warning', message: 'Max Limit is 20mb.' })
      }
    } else {
      reportFailure({ size: fileSize, type: extension })
      event.target.value = ''
      setMessage({ type: 'warning', message: 'Please select a mp4 file.' })
    }
  }
  const onUpload = async (e) => {
    e.preventDefault()
    if (addedFile) {
      setUploading(true)
      await uploadFile(
        addedFile,
        seo_name,
        setProgress,
        setMessage,
        setUploadStatus
      )
      setUploading(false)
      setProgress(0)
    } else {
      setMessage({ type: 'warning', message: 'Add a Video!' })
    }
  }
  const onReset = (e) => {
    e.preventDefault()
    setAddedFile('')
    setUploadStatus({ type: 'none', video_url: '' })
    document.getElementById('uploadInputFile').value = ''
  }
  return (
    <UploadFileContainer>
      <div
        style={{
          position: 'relative',
          padding: '1rem',
          flexGrow: '1',
          color: '#5a5a5a',
        }}
      >
        <Typography style={{ color: '#000000' }}>
          Upload file
          <span style={{ fontWeight: 200, fontSize: '10px' }}>
            {' '}
            (max limit: 20mb)
          </span>
          {uploadStatus.type === 'done' && uploadStatus.video_url ? (
            <img
              style={{ width: 30, height: 20 }}
              src={GreenTick}
              alt='submitted'
            />
          ) : (
            <span style={{ width: 30, height: 20 }} />
          )}
        </Typography>
        <Box mt='0.85rem'>
          <form>
            <div
              style={{
                width: '100%',
                clear: 'both',
                margin: '0 0 1em',
              }}
            >
              <Input
                type='file'
                name='file'
                id='uploadInputFile'
                onChange={onAddFile}
              ></Input>
            </div>
            {uploading ? (
              <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{
                    position: 'relative',
                    display: 'flex',
                    height: '5px',
                    flex: 9,
                    border: 'none',
                    margin: '1em 0em 1em',
                    background: '#e9e9e9',
                    padding: '0em',
                    borderRadius: '.28571rem',
                    overflow: 'hidden',
                  }}
                >
                  <div
                    style={{
                      background: `linear-gradient(274.34deg, #00F9FF 5.2%, #FF174E 45.73%, #FFEE1A 96.75%)`,
                      width: `${progress}%`,
                    }}
                  />
                </div>
                <Typography
                  style={{ flex: 1, textAlign: 'center', padding: '6px 4px' }}
                >
                  {progress}%
                </Typography>
              </Box>
            ) : (
              <div>
                <Button type='submit' onClick={onUpload}>
                  upload
                </Button>
                <Button type='button' onClick={onReset}>
                  reset
                </Button>
              </div>
            )}
          </form>
        </Box>
      </div>
    </UploadFileContainer>
  )
}
