import React, { useState } from 'react'
import {
  CustomModal,
  CustomModalContainer,
  ModalContentBox,
  ModalButtonBox,
} from './styles'
import { Box, Button, IconButton, CustomSnackBar } from '../ui'
import { useRecoilState } from 'recoil'
import { addedFileState } from './recoilState'
import { submit } from './uploadActions'
import { CircularProgress } from '@mui/material'
import { Form } from './components/Form'
import { Congratulations } from './components/Congratulations'
import CloseIcon from '../icons/close.svg'

export const EnterContestModal = ({ open, setOpen, seo_name, contest }) => {
  const [addedFile, setAddedFile] = useRecoilState(addedFileState)
  const [uploadStatus, setUploadStatus] = useState({
    type: 'none',
    video_url: '',
  })
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [instagram, setInstagram] = useState('')
  const [message, setMessage] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = async () => {
    setSubmitting(true)
    if (!addedFile || !name || !phoneNumber || !instagram) {
      setMessage({ type: 'error', message: 'Fill all of the Fields!' })
      setSubmitting(false)
      return
    } else if (phoneNumber.length < 10) {
      setMessage({ type: 'error', message: 'Enter valid phone number.' })
      setSubmitting(false)
      return
    } else if (uploadStatus.type !== 'done' || !uploadStatus.video_url) {
      setMessage({ type: 'error', message: 'Upload video first.' })
      setSubmitting(false)
      return
    } else {
      try {
        await submit(
          {
            contest_id: contest.contest_id,
            name: name,
            phone: phoneNumber,
            instagram: instagram,
          },
          setMessage,
          setSubmitted,
          uploadStatus
        )
      } catch (error) {
        setMessage({ type: 'error', message: 'error while submitting.' })
      }
    }
    setSubmitting(false)
  }
  const handleClose = () => {
    setOpen(false)
    setSubmitted(false)
    setAddedFile('')
    setName('')
    setPhoneNumber('')
    setInstagram('')
    setUploadStatus({
      type: 'none',
      video_url: '',
    })
  }
  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <CustomModalContainer>
        {message && (
          <CustomSnackBar message={message} setMessage={setMessage} />
        )}
        <IconButton
          style={{ position: 'absolute', top: 5, right: 5, zIndex: 20 }}
          onClick={handleClose}
        >
          <img src={CloseIcon} alt='close' />
        </IconButton>
        <ModalContentBox>
          {submitted ? (
            <Congratulations />
          ) : (
            <Form
              name={name}
              phoneNumber={phoneNumber}
              instagram={instagram}
              seo_name={seo_name}
              setName={setName}
              setPhoneNumber={setPhoneNumber}
              setInstagram={setInstagram}
              setMessage={setMessage}
              addedFile={addedFile}
              setAddedFile={setAddedFile}
              uploadStatus={uploadStatus}
              setUploadStatus={setUploadStatus}
            />
          )}
        </ModalContentBox>
        <ModalButtonBox>
          <Box style={{ alignSelf: 'center' }}>
            <Button
              variant='contained'
              style={{
                background: '#FF174E',
                padding: '10px 30px',
                width: '200px',
              }}
              onClick={submitted ? handleClose : handleSubmit}
            >
              {submitting ? (
                <CircularProgress />
              ) : submitted ? (
                'Done'
              ) : (
                'Submit'
              )}
            </Button>
          </Box>
        </ModalButtonBox>
      </CustomModalContainer>
    </CustomModal>
  )
}
