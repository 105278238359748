import React from 'react'
import { Box } from '../ui'
import { useHistory } from 'react-router-dom'
import {
  ContactUsButton,
  HeadingMedium,
  Paragraph,
  QuestionBox,
  QuestionText,
} from './styles'

const faqData = [
  {
    question: 'How do you shortlist the candidates ?',
    answer: `We Manually select all the canidadates in the first
    round. For later rounds We let our Judges and
    audience members decide who goes ahead. To
    keep it fair for everyone.`,
  },
  {
    question: 'How do you find the judges for your shows?',
    answer: `Our team reaches out to various people who we
    think would be really good for the show then we
    brainstorm ideas with the Judges/celebrity’s
    managers and come up with final rules for the
    contests that are hosted on Wakao.`,
  },
]

export const Faq = () => {
  const history = useHistory()
  return (
    <div>
      <QuestionBox>
        <HeadingMedium>FAQs</HeadingMedium>
        <Paragraph>
          Here is a list of some Frequently asked questions Go through them all
          in case you have any doubts
        </Paragraph>
      </QuestionBox>
      {faqData.map((data, index) => (
        <QuestionBox>
          <QuestionText>{data.question}</QuestionText>
          <Paragraph>{data.answer}</Paragraph>
        </QuestionBox>
      ))}
      <Box
        mt={5}
        mb={2}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <QuestionText style={{ textAlign: 'center', fontSize: '18px' }}>
          Still have some queries?
        </QuestionText>
        <ContactUsButton
          className='contactUsButton'
          onClick={() => history.push('/contactus')}
        >
          Contact Us
        </ContactUsButton>
      </Box>
    </div>
  )
}
