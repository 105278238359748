import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { contestState } from './recoilState'
import { useLocation } from 'react-router-dom'
import banner from '../landing-page/banner.jpg'
import { Box, CircularProgress, Divider, Typography } from '../ui'
import { EnterContestModal } from './EnterContestModal'
import moment from 'moment'
import axios from 'axios'
import ClockLogo from '../icons/clock.svg'
import {
  BoxContainer,
  // ContactUsButton,
  ContestDetails,
  DateContainer,
  EnterContestButton,
  HeadingMedium,
  Icon,
  Image,
  ImageBlurBackground,
  ImageContainer,
  Paragraph,
  TabsContainer,
  TabsDetailContainer,
  TabsOption,
  TabsOptionContainer,
  TitleContainer,
} from './styles'
import { Timer } from './components/Timer'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export const Contest = () => {
  const query = useQuery()
  const seo_name = query.get('seo_name')
  const [contest, setContest] = useRecoilState(contestState)
  const [value, setValue] = useState(0)
  const [open, setOpen] = useState(false)
  // const [reachedBottom, setReachedBottom] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const tabLabel = ['About', 'Rewards', 'Contest Rules']

  useEffect(() => {
    const getContest = async () => {
      setIsLoading(true)
      try {
        const { data } = await axios.get(
          `https://testapi.wakao.app/participant/contest?seo_name=${seo_name}`
        )
        setContest(data.contest)
      } catch (error) {
        console.log(error.message)
      }
      setIsLoading(false)
    }
    getContest()
  }, [seo_name])

  const handleChange = (e, newValue) => {
    setValue(newValue)
  }

  const getDateObject = (date) => {
    var temp = date.split(/[- :]/)
    var d = new Date(
      parseInt(temp[0]),
      parseInt(temp[1]) - 1,
      parseInt(temp[2]),
      parseInt(temp[3]),
      parseInt(temp[4]),
      parseInt(temp[5])
    )
    var actiondate = new Date(d)
    return actiondate
  }
  const TABS = [
    contest ? <div>{contest.about}</div> : <div>Loading...</div>,
    contest ? <div>{contest.rewards}</div> : <div>Loading...</div>,
    contest ? <div>{contest.rules}</div> : <div>Loading...</div>,
  ]
  return contest ? (
    <BoxContainer>
      <ImageContainer>
        <ImageBlurBackground
          style={{
            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),${
              contest.image_url ? `url(${contest.image_url})` : `url(${banner})`
            }`,
          }}
        ></ImageBlurBackground>
        <Image
          style={{
            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),${
              contest.image_url ? `url(${contest.image_url})` : `url(${banner})`
            }`,
          }}
        ></Image>
        <TitleContainer>
          <HeadingMedium>{contest.title}</HeadingMedium>
          <Paragraph>{contest.description}</Paragraph>
        </TitleContainer>
      </ImageContainer>
      {!isLoading ? (
        <ContestDetails>
          <Timer
            startTime={
              Object.keys(contest).length > 0 && getDateObject(contest.start_by)
            }
            endTime={
              Object.keys(contest).length > 0 && getDateObject(contest.end_by)
            }
          />
          <DateContainer>
            <Icon src={ClockLogo} alt='Clock Logo' />
            <Typography>
              {moment(contest.start_by).format('DD MMMM YYYY')} -{' '}
              {moment(contest.end_by).format('DD MMMM YYYY')}
            </Typography>
          </DateContainer>
          <TabsContainer>
            <TabsOptionContainer
              TabIndicatorProps={{
                style: { background: '#FF174E' },
              }}
              value={value}
              onChange={handleChange}
              variant='scrollable'
              scrollButtons='auto'
            >
              {tabLabel.map((item) => (
                <TabsOption
                  style={{ color: '#FF174E' }}
                  label={item}
                  key={item}
                />
              ))}
            </TabsOptionContainer>
            <Divider />
            <TabsDetailContainer>
              {/* onScroll={handleScroll} */}
              {TABS[value]}
            </TabsDetailContainer>
          </TabsContainer>
        </ContestDetails>
      ) : (
        <CircularProgress />
      )}
      {!open && (
        <EnterContestButton
          // reachedbottom={reachedBottom}
          className='myEnterContestButton'
          onClick={() => setOpen(true)}
        >
          Enter Contest Now
        </EnterContestButton>
      )}
      <Box m={2} />
      {/* <ContactUsButton
        reachedbottom={reachedBottom}
        className='myContactUsButton'
      >
        Contact Us
      </ContactUsButton> */}
      <EnterContestModal
        open={open}
        setOpen={setOpen}
        seo_name={seo_name}
        contest={contest}
      />
    </BoxContainer>
  ) : (
    <CircularProgress />
  )
}
