import React, { useEffect, useState } from 'react'
import { Box, Typography } from '../../ui'
import { TimerText } from '../styles'

export const Timer = ({ startTime, endTime }) => {
  const deadline = new Date(endTime).getTime()
  var now = new Date().getTime()
  var t = (deadline > 0 ? deadline : now) - now
  var daysLeft = Math.floor(t / (1000 * 60 * 60 * 24))
  var hoursLeft = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  var minutesLeft = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60))
  var secondsLeft = Math.floor((t % (1000 * 60)) / 1000)
  const [[days, hrs, mins, secs], setTime] = useState([
    daysLeft,
    hoursLeft,
    minutesLeft,
    secondsLeft,
  ])

  const tick = () => {
    if (days === 0 && hrs === 0 && mins === 0 && secs === 0) {
      console.log('Times Up')
    } else if (hrs === 0 && mins === 0 && secs === 0) {
      setTime([days - 1, 59, 59, 59])
    } else if (mins === 0 && secs === 0) {
      setTime([days, hrs - 1, 59, 59])
    } else if (secs === 0) {
      setTime([days, hrs, mins - 1, 59])
    } else {
      setTime([days, hrs, mins, secs - 1])
    }
  }
  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000)
    return () => clearInterval(timerId)
  })

  return (
    <Box
      style={{
        position: 'relative',
        padding: '10px 10px 10px 0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        maxWidth: 400,
      }}
    >
      <TimerText style={{ flex: 2 }}>
        <span style={{ fontSize: '30px', transform: `translateY(-4px)` }}>
          {days}
        </span>
        D
      </TimerText>
      <TimerText style={{ flex: 2 }}>
        <span style={{ fontSize: '30px', transform: `translateY(-4px)` }}>
          {hrs}
        </span>
        H
      </TimerText>
      <TimerText style={{ flex: 2 }}>
        <span style={{ fontSize: '30px', transform: `translateY(-4px)` }}>
          {mins}
        </span>
        M
      </TimerText>
      <TimerText style={{ flex: 2 }}>
        <span style={{ fontSize: '30px', transform: `translateY(-4px)` }}>
          {secs}
        </span>
        S
      </TimerText>
      <TimerText
        style={{
          flex: 3.5,
          justifyContent: 'flex-start',
          margin: 5,
        }}
      >
        <span style={{ fontSize: '15px', lineHeight: '15px' }}>Time Left</span>
      </TimerText>
    </Box>
  )
}
