import React, { useState } from 'react'
import Logo from '../icons/logo.svg'
import {
  Nav,
  NavCompany,
  NavLogo,
  NavTitle,
  NavLink,
  // Bar,
  NavMenu,
  // NavBtn,
  // NavBtnLink,
  BarsContainer,
  NavBarHamMenu,
  NavBarHamMenuItem,
  NavContainer,
  Divider,
} from './styles'

export const NavBar = () => {
  const [openMenu, setOpenMenu] = useState(false)
  return (
    <NavContainer open={openMenu}>
      <Nav>
        <BarsContainer open={openMenu} onClick={() => setOpenMenu(!openMenu)}>
          <div />
          <div />
          <div />
        </BarsContainer>
        <NavCompany to='/'>
          <NavLogo src={Logo} />
        </NavCompany>

        <NavMenu>
          <NavLink exact to='/' activeStyle>
            Home
          </NavLink>
          <NavLink exact to='/faqs' activeStyle>
            FAQs
          </NavLink>
          <NavLink exact to='/contactus' activeStyle>
            Contact Us
          </NavLink>
          {/* Second Nav */}
          {/* <NavBtnLink to='/sign-in'>Sign In</NavBtnLink> */}
        </NavMenu>
      </Nav>
      {/* <NavBtn>
          <NavBtnLink to='/signin'>Sign In</NavBtnLink>
        </NavBtn> */}
      <NavBarHamMenu open={openMenu}>
        <NavBarHamMenuItem
          exact
          to='/'
          activeStyle
          onClick={() => setOpenMenu(false)}
        >
          Home
        </NavBarHamMenuItem>
        <Divider />
        <NavBarHamMenuItem
          exact
          to='/faqs'
          activeStyle
          onClick={() => setOpenMenu(false)}
        >
          FAQs
        </NavBarHamMenuItem>
        <Divider />
        <NavBarHamMenuItem
          exact
          to='/contactus'
          activeStyle
          onClick={() => setOpenMenu(false)}
        >
          Contact Us
        </NavBarHamMenuItem>
      </NavBarHamMenu>
    </NavContainer>
  )
}
