import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { NavBar } from './ui'
import { Contest } from './contests'
import { Faq } from './faq'
import { ContactUs } from './contactUs'
import './App.css'
import { RecoilRoot } from 'recoil'
// import { LandingPage } from './landing-page'
// import { Team } from './team'

function App() {
  return (
    <RecoilRoot>
      <Router>
        {/* <Background /> */}
        <NavBar />
        <Switch>
          <Route path='/' strict exact>
            <Redirect to='/contests?seo_name=dance-plus' />
          </Route>
          <Route path='/contests' exact component={Contest} />
          <Route path='/faqs' exact component={Faq} />
          <Route path='/contactus' exact component={ContactUs} />
          {/* <Route path='/team' exact component={Team} /> */}
        </Switch>
      </Router>
    </RecoilRoot>
  )
}

export default App
