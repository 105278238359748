import { NavLink as Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'

const Gradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`
const cube = keyframes`
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }
  to {
    transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
`
export const BackgroundContainer = styled.div`
  background: linear-gradient(132deg, #fc415a, #591bc5, #212335);
  background-size: 400% 400%;
  animation: ${Gradient} 15s ease infinite;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: -15;
  overflow: hidden;
  padding: 0;
  margin: 0px;
  &:nth-child(2n) {
    border-color: #fff;
  }
  &:nth-child(2) {
    animation-delay: 2s;
    left: 25vw;
    top: 40vh;
  }
  &:nth-child(3) {
    animation-delay: 4s;
    left: 75vw;
    top: 50vh;
  }
  & div:nth-child(4) {
    animation-delay: 6s;
    left: 90vw;
    top: 10vh;
  }
  & div:nth-child(5) {
    animation-delay: 8s;
    left: 10vw;
    top: 85vh;
  }
  & div:nth-child(6) {
    animation-delay: 10s;
    left: 50vw;
    top: 10vh;
  }
`
export const Cube = styled.div`
  position: absolute;
  top: 80vh;
  left: 45vw;
  width: 10px;
  height: 10px;
  border: solid 1px #d7d4e4;
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  animation: ${cube} 12s ease-in forwards infinite;
`

export const NavContainer = styled.nav`
  z-index: 12;
  background-color: #000000db;
  max-height: ${({ open }) => (open ? '100vh' : '3rem')};
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin-bottom: 0px;
  z-index: 12;
  transition: max-height 1s ease;
  /* Third Nav */
  /* justify-content: flex-start; */
  /* &:hover {
    background-color: #0000009a;
  } */
`
export const Nav = styled.div`
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Third Nav */
  /* justify-content: flex-start; */
`
export const NavCompany = styled(Link)`
  background: inherit;
  height: 2rem;
  display: flex;
  order: 1;
  align-items: center;
  text-decoration: none;
  justify-content: space-between;
  margin: 0 10px;
  padding: 0.5rem;
`
export const NavLogo = styled.img`
  height: 2rem;
`
export const NavTitle = styled.h3`
  margin: 0;
  color: #fff;
  height: auto;
`

export const NavMenu = styled.div`
  display: flex;
  order: 2;
  align-items: center;
  margin-right: 10%;
  /* Second Nav */
  /* margin-right: 24px; */
  /* Third Nav */
  /* width: 100vw;
  white-space: nowrap; */
  @media screen and (max-width: 768px) {
    display: none;
  }
`
export const NavLink = styled(Link)`
  color: hsla(0, 0%, 100%, 0.5);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &.active {
    color: #ffffff;
  }
  &:hover {
    color: #ffffff;
  }
`

export const BarsContainer = styled.button`
  display: none;
  color: #808080;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    order: 3;
    align-items: center;
    justify-content: space-around;
    width: 3rem;
    height: 2.2rem;
    margin: 10px 15px;
    cursor: pointer;
    padding: 0.3rem 0.3rem;
    border-radius: 3px;
    background-color: transparent;
    border: 0.1rem solid hsla(0, 0%, 100%, 0.1);
    z-index: 15;
    box-shadow: ${({ open }) =>
      open &&
      `
      rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
        rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
        rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
      
      `};
    div {
      position: relative;
      display: block;
      width: 1.4rem;
      height: 0.15rem;
      background: linear-gradient(
        305.08deg,
        #00f9ff 1.23%,
        #ff174e 43.88%,
        #ffee1a 97.56%
      );
      border-radius: 10px;
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 4px;
      outline: 0;
      :first-child {
        transform: ${({ open }) =>
          open ? 'rotate(45deg) translateX(3px)' : 'rotate(0)'};
      }

      :nth-child(2) {
        opacity: ${({ open }) => (open ? '0' : '1')};
      }

      :nth-child(3) {
        transform: ${({ open }) =>
          open ? 'rotate(-45deg) translateX(3px)' : 'rotate(0)'};
      }
    }
  }
`
export const Bar = styled.div`
  position: relative;
  display: block;
  width: 1.4rem;
  height: 0.15rem;
  box-sizing: border-box;
  margin: 0.15rem;
  background: #effffa;
`

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;
  /* Third Nav */
  /* justify-content: flex-end;
  width: 100vw; */
  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  background: #808080;
  padding: 10px 22px;
  color: #000000;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  /* Second Nav */
  margin-left: 24px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #808080;
  }
`

export const NavBarHamMenu = styled.nav`
  display: flex;
  flex-direction: column;
  background: #000000d9;
  transform: ${({ open }) => (open ? 'translateX(0%)' : 'translateX(100%)')};
  text-align: right;
  padding: 4rem;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  transition: transform 0.2s ease-in-out;
  z-index: 10;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 80%;
  }
`
export const NavBarHamMenuItem = styled(Link)`
  position: relative;
  width: 100%;
  margin: 5px 0;
  color: hsla(0, 0%, 100%, 0.5);
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-sizing: border-box;
  padding: 0 1rem;
  cursor: pointer;
  text-decoration: none;
  &.active {
    color: #ffffff;
  }
`
export const Divider = styled.hr`
  border: 0;
  border-top: 1px solid rgba(255 255 255 / 45%);
  height: 1px;
  width: 100%;
  /* background-color: rgba(0 0 0 / 45%); */
`
