import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box } from '../ui'
import { CustomButton, HeadingMedium, Paragraph, ContactBox } from './styles'
import Whatsapp from '../icons/whatsapp.svg'

export const ContactUs = () => {
  const history = useHistory()
  return (
    <div>
      <ContactBox>
        <HeadingMedium>Contact Us</HeadingMedium>
        <Paragraph>
          Have some unanswered questions? Reach out to us and our Team will
          gladly answer those.
        </Paragraph>
      </ContactBox>
      <Box
        mt={5}
        mb={2}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CustomButton
          className='customButton'
          style={{ background: '#25D366' }}
        >
          <img src={Whatsapp} alt='whatsapp' style={{ marginRight: '10px' }} />
          Reach out on Whatsapp
        </CustomButton>
        <CustomButton className='customButton'>Send an Email</CustomButton>
      </Box>
    </div>
  )
}
