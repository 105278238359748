import styled from 'styled-components'
import { Modal } from '@mui/material'
import { Button, Tab, Tabs, Typography } from '../ui'

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 12;
`

export const Image = styled.div`
  position: absolute;
  display: block;
  top: 0px;
  left: 0;
  right: 0;
  align-self: center;
  z-index: -10;
  width: 100%;
  height: 100%;
  align-self: center;
  background-size: cover;
  background-position: center;
  opacity: 1;
  @media screen and (min-width: 500px) {
    width: 500px;
    height: 380px;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  }
`
export const ImageBlurBackground = styled.div`
  position: absolute;
  display: none;
  top: 0px;
  left: 0px;
  right: 0;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  z-index: -20;
  width: 100%;
  height: 100%;
  align-self: center;
  background-size: cover;
  background-position: center;
  opacity: 0.5;
  @media screen and (min-width: 500px) {
    display: block;
  }
`
export const TitleContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 10px;
  color: #ffffff;
  border-radius: 10px 10px 0 0;
  box-sizing: border-box;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.0186) 0%,
    rgba(0, 0, 0, 0.31) 54.69%
  );
  @media screen and (min-width: 500px) {
    padding: 20px 40px;
  }
`
export const Paragraph = styled.h4`
  position: relative;
  text-align: left;
  transition: transform 500ms;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 400;
  opacity: 1;
  margin: 5px 5px 10px 5px;
  box-sizing: border-box;
`
export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 300px;
  &:hover {
    ${Paragraph} {
      transform: translateX(5px);
    }
  }
  @media screen and (max-width: 499px) {
    /* aspect-ratio: 4/3 !important; */
  }
  @media screen and (min-width: 500px) {
    height: 400px;
  }
`
export const ContestDetails = styled.div`
  position: relative;
  top: -10px;
  width: 100%;
  padding: 20px 10px 10px 10px;
  height: auto;
  box-sizing: border-box;
  border-radius: 10px 10px 0 0;
  z-index: 0;
  background-color: #fff;
  @media screen and (min-width: 500px) {
    padding: 30px 40px 30px 40px;
  }
`
export const HeadingMedium = styled.h3`
  position: relative;
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 25px;
  margin: 5px 0;
`
export const TabsContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  min-height: 200px;

  padding: 10px 0;
  margin-bottom: 70px;
  box-sizing: border-box;
`
export const TabsOptionContainer = styled(Tabs)`
  display: flex;
  justify-content: space-between;
`
export const TabsOption = styled(Tab)`
  padding: 10px;
`

export const TabsDetailContainer = styled.div`
  position: relative;
  max-height: 400px;
  overflow-y: auto;
  padding: 10px 10px;
  box-sizing: border-box;
`

export const EnterContestButton = styled(Button)`
  &.myEnterContestButton {
    position: fixed;
    cursor: pointer;
    width: fit-content;
    height: auto;
    border-radius: 6px;
    color: #ffffff;
    font-size: 18px;
    bottom: 50px;
    padding: 10px 20px;
    margin: 0 auto;
    background: #ff174e;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    transition: all 0.3s linear;

    &:hover {
      background: #ff174e;
    }
  }
`
export const ContactUsButton = styled(Button)`
  &.myContactUsButton {
    position: fixed;
    cursor: pointer;
    width: fit-content;
    height: 50px;
    border-radius: 6px;
    color: #ffffff;
    font-size: 18px;
    bottom: -50px;
    padding: 0 20px;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.9);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    transition: all 0.3s linear;
    transform: ${({ reachedbottom }) => reachedbottom && `translateY(-80px)`};

    &:hover {
      background-color: rgba(0, 0, 0, 1);
    }
  }
`
export const CustomModal = styled(Modal)``
export const CustomModalContainer = styled.div`
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 600px;
  height: 85vh;
  background: rgb(255 255 255 / 80%);
  box-shadow: 0 8px 32px 0 rgb(0 0 0 / 37%);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 24;
  padding: 1rem;
  color: #000000;
`
export const ModalContentBox = styled.div`
  position: relative;
  overflow-y: auto;
  height: 90%;
  padding: 0.5rem;
  &::-webkit-scrollbar-track {
    width: 4px;
    height: 80%;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    border-radius: '1px';
    background: transparent;
  }
  &::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 40px;
  }

  &::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 40px;
  }
  &::-webkit-scrollbar {
    height: 80%;
    width: 2px;
    background-color: inherit;
  }
  &::-webkit-scrollbar-thumb {
    visibility: hidden;
    border-radius: 1px;
    box-shadow: 'inset 0 0 6px rgba(0,0,0,.3)';
    -webkit-box-shadow: 'inset 0 0 6px rgba(0,0,0,.3)';
    background-color: #ffd369;
  }
  &:hover,
  &:focus {
    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
`
export const ModalButtonBox = styled.div`
  position: relative;
  height: 10%;
  max-height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const UploadFileContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 1rem 0em;
  border: 1px solid rgb(177 174 183);
  border-radius: 0.28571rem;
  box-shadow: 0px 1px 2px 0 #f1f0f0;
  transition: box-shadow 0.1s ease, transform 0.1s ease;
  color: #5a5a5a;
  background: inherit;
`
export const Input = styled.input`
  transition: box-shadow 0.1s ease-in-out;
  line-height: 1.2142em;
  border: 1px solid rgb(177 174 183);
  padding: 0.78571em 1em;
  border-radius: 0.28571rem;
  color: #646464;
  background: inherit;
  width: 100%;
  min-width: 1px;
  box-sizing: border-box;
  input:not([type='radio']) {
  }
`
export const DateContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 5px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`
export const Icon = styled.img`
  position: relative;
  width: 25px;
  height: 16px;
`
export const TimerText = styled(Typography)`
  margin: 0 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
