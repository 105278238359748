import React from 'react'
import { Box, Typography } from '../../ui'
import BigLogo from '../../icons/bigLogo.svg'

export const Congratulations = () => {
  return (
    <Box
      style={{
        margin: 'auto',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        style={{ fontWeight: 'bold', fontSize: '34px', textAlign: 'center' }}
      >
        All the Best
      </Typography>
      <Typography
        style={{ fontWeight: 500, fontSize: '22px', textAlign: 'center' }}
      >
        Your Entry has been recorded
      </Typography>
      <img src={BigLogo} alt='Wakao' style={{ margin: '20px' }} />
      <Typography
        style={{ fontWeight: 'normal', fontSize: '16px', textAlign: 'center' }}
      >
        We will keep you updated over your email.
      </Typography>
    </Box>
  )
}
