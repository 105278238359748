import axios from 'axios'

export const getS3SignedUploadUrl = async (
  seo_name,
  setMessage,
  setUploadStatus
) => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
    },
  }
  try {
    const { data } = await axios.get(
      `https://testapi.wakao.app/participant/submission/upload?seo_name=${seo_name}&extension=mp4`,
      config
    )
    setUploadStatus({ type: 'none', video_url: data.video_url })
    return data
  } catch (error) {
    setUploadStatus({ type: 'failed', video_url: '' })
    setMessage({ type: 'error', message: `Sorry.We can't reach our servers` })
  }
}

export const uploadFile = async (
  file,
  seo_name,
  setProgress,
  setMessage,
  setUploadStatus = () => {}
) => {
  const { signed_url, video_url } = await getS3SignedUploadUrl(
    seo_name,
    setMessage,
    setUploadStatus
  )

  const config = {
    headers: {
      'Content-Type': 'video/mp4',
    },
    onUploadProgress: (progressEvent) => {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      )
      setProgress(percentCompleted)
    },
  }
  await axios
    .put(`${signed_url}`, file, config)
    .then((res) => {
      setUploadStatus({ type: 'done', video_url: video_url })
    })
    .catch((err) => {
      video_url = ''
      setUploadStatus({ type: 'failed', video_url: '' })
      setMessage({ type: 'error', message: 'Error while Uploading.' })
    })
}

export const submit = async (
  formObject,
  setMessage,
  setSubmitted,
  uploadStatus
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const jsonObject = JSON.stringify({
    ...formObject,
    video_url: uploadStatus.video_url,
  })
  try {
    const { data } = await axios.post(
      `https://testapi.wakao.app/participant/submission`,
      jsonObject,
      config
    )
    setSubmitted(true)
  } catch (error) {
    setMessage({
      type: 'error',
      message: 'we are facing issues on our server, please try again later.',
    })
  }
}

export const reportFailure = async (object) => {
  try {
    const response = await axios.post(
      'https://testapi.wakao.app/participant/submission/failed',
      JSON.stringify(object)
    )
  } catch (err) {
    return
  }
}
