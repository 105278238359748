import { atom, selector } from 'recoil'

//contest state
export const contestState = atom({
  key: 'contestState',
  default: {},
})

// added video
export const addedFileState = atom({
  key: 'addedFileState',
  default: '',
})
// uploading progress state
export const uploadProgressState = atom({
  key: 'uploadProgressState',
  default: 0,
})
