import React, { useState, forwardRef, useEffect } from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import Slide from '@mui/material/Slide'

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})
const SlideTransition = (props) => {
  return <Slide {...props} direction='down' />
}

export const CustomSnackBar = ({ message, setMessage }) => {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(Object.keys(message).length > 0 ? true : false)
  }, [message])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
    setTimeout(() => setMessage({}), 1000)
  }
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
      autoHideDuration={6000}
      TransitionComponent={SlideTransition}
      key='topcenter'
    >
      <Alert onClose={handleClose} severity={message.type}>
        {message.message}
      </Alert>
    </Snackbar>
  )
}
